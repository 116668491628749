var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',[_c('header',[_c('h2',[_vm._v("Ceridian Dayforce Integration")]),_c('button',{staticClass:"close",on:{"click":_vm.close}},[_c('span',{staticClass:"sr-only"},[_vm._v("Close modal window")])])]),_c('main',[_c('div',{staticClass:"setting-row"},[_vm._m(0),_c('div',{staticClass:"fields"},[(_vm.active)?[_c('input',{staticClass:"form-control",attrs:{"type":"text","readonly":"","disabled":""},domProps:{"value":_vm.integration.configuration.api_key}})]:_c('form',{staticClass:"dayforce-auth",on:{"submit":function($event){$event.preventDefault();return _vm.tryAuth.apply(null, arguments)}}},[_c('p',[_vm._v("Your Dayforce credentials are securely saved for future syncing.")]),_vm._m(1),_vm._m(2),_vm._m(3),_c('button',{staticClass:"btn btn-dayforce btn-block",class:{'loading': _vm.loading},attrs:{"disabled":_vm.loading}},[_vm._v("Login to Dayforce")])])],2)]),(_vm.active)?_c('div',{staticClass:"setting-row"},[_vm._m(4),_c('div',{staticClass:"fields stats"},[_c('ul',[_c('li',{staticClass:"status"},[_c('strong',[_vm._v("Status")]),_c('span',{class:[_vm.integration.status]},[_c('span',[_vm._v(_vm._s(_vm.status_label))])])]),_c('li',[_c('strong',[_vm._v("Last sync")]),_c('span',[_vm._v(_vm._s(_vm.last_sync_time))])])])])]):_vm._e(),_c('div',{staticClass:"setting-row"},[_vm._m(5),_c('div',{staticClass:"fields"},[(_vm.logs.length > 0)?_c('ul',_vm._l((_vm.logs),function(log,index){return _c('li',{key:`log_${index}`},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openLog(log)}}},[_vm._v(_vm._s(_vm.$formatDate(log.created_at, _vm.$DateTime.DATETIME_SHORT)))])])}),0):_c('span',[_vm._v("No logs available.")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h4',[_vm._v("Credentials")]),_c('p',[_vm._v("Dayforce web services user credentials.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"company"}},[_vm._v("Dayforce Company")]),_c('span',{staticClass:"secure-field",attrs:{"id":"company"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"username"}},[_vm._v("Dayforce Username")]),_c('span',{staticClass:"secure-field",attrs:{"id":"username"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Dayforce Password")]),_c('span',{staticClass:"secure-field",attrs:{"id":"password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h4',[_vm._v("Statistics")]),_c('p',[_vm._v("Latest details about your integration.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h4',[_vm._v("Sync Logs")]),_c('p',[_vm._v("View synchronization activity for the last 72 hours.")])])
}]

export { render, staticRenderFns }